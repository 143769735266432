$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    // create instance of the plugin, but hold on till needed
    var acc = $('.accHandler').accordion({onhold: true, openFirst: false});
    $win = $(window);


    // start the accordion when window is smaller than 902px
    $win.resize(function() {
        if (breakpoint['big']) acc.destroy();
        else acc.init();
    });
  
    // trigger resize to check if accordion is needed
    $win.trigger('resize');

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * REPLACE a[href^="tel"] BY SPAN ELEMENT IF NOT MOBILE DEVICE
     */
    if(!('ontouchstart' in window || navigator.maxTouchPoints)) {
        phonelink();
    }

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        maxWidth: $(window).width() * 0.8,
        maxHeight: $(window).height() * 0.8,
        shrinkFactor:1,
        type: 'image'
    });

     $(window).scroll(function() {    
        var scroll = $(window).scrollTop();

        if (scroll >= 50) {
            $(".navi-main").addClass("navi-main01");
        } 

        else {
            $(".navi-main").removeClass("navi-main01");
        } 

        if (scroll >= 50) {
            $(".page-navi").addClass("page-navi01");
        } 

        else {
            $(".page-navi").removeClass("page-navi01");
        } 

        if (scroll >= 50) {
            $(".branding").addClass("branding01");
        } 

        else {
            $(".branding").removeClass("branding01");
        } 
    });
   

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC.
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if (breakpoint['medium']) {

    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');

});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/